import {
	defaultMapPointImage,
	getImageLink,
} from '@/components/shared/map/point/Point'
import { getMapCoord } from '@/components/shared/map/polygon/Polygon'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Group, Rect, Text } from 'react-konva'
import useImage from 'use-image'
import { isNonNullable } from '@/components/layout/AppBar/user/User'
import { API } from '@/api'

export type CrossOriginType = 'anonymous' | 'use-credentials' | undefined

const useUserMapAvatar = (
	userId: number | string | undefined | null,
	apiUrl: string,
) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [data, setData] = useState<string | null>(null)

	const fetchImage = async () => {
		try {
			setIsLoading(true)
			const response = await API.user.getAvatar({
				userId: Number(userId),
				url: apiUrl,
			})
			const objectData = response ? URL.createObjectURL(response) : null
			setData(objectData)
		} catch (e) {
			console.error('failed to fetch user map avatar')
		}
		setIsLoading(false)
	}

	useEffect(() => {
		if (isNonNullable(userId) && Number(userId) > 0) {
			fetchImage()
		}
	}, [userId])

	return {
		src: data,
		isLoading,
	}
}

const useMapAvatar = (bookingData, node, url, sourceType) => {
	const userAvatar = useUserMapAvatar(bookingData?.user_id, url)
	const imgSrc = bookingData
		? userAvatar.src
		: getImageLink(node?.icon, url) || getImageLink(defaultMapPointImage, url)
	const [pointImg] = useImage(imgSrc || '', sourceType)

	return { image: pointImg }
}

const BasicPoint = (props) => {
	const {
		colors,
		point,
		options,
		nodes,
		bookings,
		hasDepartment,
		bookingData,
		allBookings,
		available,
		node,
		url,
		sourceType,
	} = props

	const layers = useMapStore((state) => state.layers)
	const setSeat = useGlobalStore((state) => state.setSeat)
	const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
	const [width, height] = useMapStore((state) => state.size)
	const setTooltip = useMapStore((state) => state.setTooltip)

	const textRef = useRef<any>(null)
	const groupRef = useRef<any>(null)
	const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 })

	const { id, x, y, name, type_uid, plugin_data } = point
	const { labelSize, fontSize, color, borderWidth, wrapText } = options

	const isEmployeeVisible = layers['employees']
	const { image } = useMapAvatar(
		isEmployeeVisible ? bookingData : null,
		node,
		url,
		sourceType,
	)
	const bookingCustomData = bookingData
		? bookingData['booking_custom_data']
		: ''
	const text = isEmployeeVisible ? bookingData?.display || name : name
	const tempText = wrapText ? text.split(' ').join('\n') : text
	const displayText = bookingCustomData
		? [tempText, bookingCustomData].join('\n')
		: tempText

	const onSelect = useCallback(() => {
		setSeat(Number(point.id))
		if (bookingData) {
			setSeatEmployee(Number(bookingData.user_id))
		} else {
			setSeatEmployee(null)
		}
	}, [setSeat, setSeatEmployee, bookingData, point])

	const onMouseEnterHandler = React.useCallback((e) => {
		const container = e.target.getStage()?.container()

		if (container) {
			container.style.cursor = 'pointer'
		}
	}, [])

	const onMouseLeaveHandler = React.useCallback((e) => {
		const container = e.target.getStage()?.container()

		if (container) {
			container.style.cursor = 'default'
		}
	}, [])

	const onGroupMouseEnterHandler = React.useCallback(
		(e) => {
			setTooltip(name)
		},
		[setTooltip, name],
	)

	const onGroupMouseLeaveHandler = React.useCallback(
		(e) => {
			setTooltip(null)
		},
		[setTooltip],
	)

	const getColor = useMemo(() => {
		if (!hasDepartment || !Object.keys(colors).length) return node?.border
		const color = colors[hasDepartment]

		if (!hasDepartment || !color) return node?.border
		return color
	}, [point, node, hasDepartment, colors])

	useEffect(() => {
		if (width > 0 && height > 0) {
			const pointX =
				-textRef.current?.textWidth / 2 + (width * labelSize) / 2 ||
				(width * labelSize) / 2
			setCoord({
				x: getMapCoord(width, x),
				y: getMapCoord(height, y),
				pX: Number(pointX),
			})
		}
	}, [textRef.current, image, width, height, displayText])

	useEffect(() => {
		if (!groupRef.current) return
		groupRef.current.on('mouseenter', onGroupMouseEnterHandler)
		groupRef.current.on('mouseleave', onGroupMouseLeaveHandler)
	}, [groupRef.current])

	return (
		<Group
			x={coord.x}
			y={coord.y}
			offsetX={(width * labelSize) / 2}
			offsetY={(width * labelSize) / 2}
			onClick={onSelect}
			onTap={onSelect}
			listening={true}
		>
			<Group
				onMouseEnter={onMouseEnterHandler}
				onMouseLeave={onMouseLeaveHandler}
				opacity={available ? 1 : 0.3}
				id={'point' + point.id}
				ref={groupRef}
			>
				<Rect
					width={width * labelSize}
					height={width * labelSize}
					stroke={getColor}
					strokeWidth={fontSize * borderWidth}
					fill={node?.background}
					cornerRadius={node?.radius}
					shadowForStrokeEnabled={false}
					perfectDrawEnabled={false}
					listening={available}
				/>
				{image && (
					<Rect
						width={width * labelSize}
						height={width * labelSize}
						cornerRadius={node?.radius}
						fillPatternImage={image}
						fillPatternScaleX={(width * labelSize) / image?.width}
						fillPatternScaleY={(width * labelSize) / image?.width}
						shadowForStrokeEnabled={false}
						perfectDrawEnabled={false}
					/>
				)}
			</Group>
			<Text
				ref={textRef}
				text={displayText}
				align="center"
				fontSize={fontSize}
				fill={color}
				y={width * 1.1 * labelSize}
				x={coord.pX}
				listening={available}
				perfectDrawEnabled={false}
			/>
		</Group>
	)
}

export default BasicPoint
