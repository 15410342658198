// import React from 'react'
// import styled from 'styled-components'
// import { useGlobalStore } from '@/stores/globalStore';
// import { media } from '@/ui/media';
// import { useLayerInfo } from '@/api/hooks/useLayerInfo';
// import { translate } from '@/i18n';

// const FloorBadge = () => {
//     const activeLayer = useGlobalStore(state => state.activeLayer)

//     const { data, isLoading } = useLayerInfo(activeLayer)

//     if (!data || !data.info && !isLoading) return null

//     const badge = () => {
//         const hasOwnView = data.info.own_view
//         return hasOwnView ? [...data.info.parent_name, data.info.name] : data.info.parent_name
//     }

//     return (
//         <Wrapper>
//             {isLoading ? translate('loading') : badge().join('. ')}
//         </Wrapper>
//     )
// }

// export default FloorBadge

// const Wrapper = styled.div`
//     background: #38056C;
//     box-sizing: border-box;
//     border-radius: 4px;
//     font-size: 1.6rem;
//     line-height: 3rem;
//     color: #f5f5f5;
//     padding: 1rem;
//     max-width: 400px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-align: center;

//     position: fixed;
//     left: 50%;
//     transform: translateX(-50%);
//     top: 95px;
//     z-index: 199;
//     user-select: none;

//     pointer-events: none;

//     ${media.lg`
//         left: 10px;
//         transform: translateX(0%);
//     `}
// `
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useGlobalStore } from '@/stores/globalStore'
import { media } from '@/ui/media'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { translate } from '@/i18n'
import FloorSelector from '@/components/floor-selector/FloorSelector'
import { useTree } from '@/api/hooks/useTree'

const FloorBadge = () => {
	const activeLayer = useGlobalStore((state) => state.activeLayer)
	const { layers } = useTree()

	const name = useMemo(() => {
		const currentLayer = layers?.nodes.find((layer) => layer.id == activeLayer)

		if (!currentLayer) return ''

		const validLayerId = currentLayer.ownView
			? currentLayer.id
			: currentLayer.parent
		const actualLayer = layers?.nodes.find((l) => l.id == validLayerId)

		return actualLayer?.name || ''
	}, [activeLayer, layers?.nodes])

	// const { data, isLoading } = useLayerInfo(activeLayer)

	// if (!data || !data.info && !isLoading) return null

	// const badge = () => {
	//     const hasOwnView = data.info.own_view
	//     return hasOwnView ? [...data.info.parent_name, data.info.name] : data.info.parent_name
	// }

	return (
		<Wrapper>
			{/*{isLoading ? translate('loading') : badge().join('. ')}*/}
			<FloorSelector text={name} />
		</Wrapper>
	)
}

export default FloorBadge

const Wrapper = styled.div`
	background: #079dac;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 1.6rem;
	line-height: 3rem;
	color: #ffffff;
	max-width: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	top: 95px;
	z-index: 199;
	user-select: none;

	//pointer-events: none;

	${media.lg`
        left: 10px;
        transform: translateX(0%);
    `}
`
