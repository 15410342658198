import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { api } from '@/api'
import axios from 'axios'

export const formatURL = (url: string) => {
	return url.replace(/([^:]\/)\/+/g, '$1')
}

const axiosInstance = axios.create({
	validateStatus: (status) => status === 200,
	responseType: 'json',
	headers: {
		'Cache-Control': 'max-age=900',
	},
})

export function isNonNullable<T>(value: T): value is NonNullable<T> {
	return typeof value !== 'undefined' && value !== null
}

export const fetchJSON = async <T,>(
	url: string,
	error = 'Can not load JSON from remote server',
) => {
	const response = await axiosInstance.get<T>(url)

	if (typeof response.data !== 'object') {
		throw new Error(error)
	}

	return response.data
}

const fetchSettings = async (version: number) =>
	await fetchJSON<any>(
		// TODO: mb return cache ?v=
		`/settings.json?v=${version}`,
		'No settings file found',
	)

const getFormattedApiURL = (url: string | undefined | null) => {
	if (!url || !isNonNullable(url)) return '/'

	return formatURL(url.toLowerCase())
}

const getWithCredentials = (param: boolean | string | undefined | null) => {
	if (!isNonNullable(param)) return false
	const lowerCaseParam =
		typeof param === 'boolean' ? String(param) : String(param).toLowerCase()

	return lowerCaseParam == 'true'
}

export const useSettings = () => {
	const { enqueueToast } = useToast()
	return useQuery({
		queryKey: ['settings'],
		queryFn: () => fetchSettings(Date.now()),
		staleTime: 5 * 60 * 1000,
		retry: false,
		// TODO: refactor for v5 @tanstack/react-query where onSuccess and onError will be remover from useQuery
		onSuccess: ({ api: apiSettings }) => {
			api.defaults.baseURL = getFormattedApiURL(apiSettings['url'])
			api.defaults.withCredentials = getWithCredentials(
				apiSettings['withCredentials'],
			)
		},
		select: (data) => ({
			...data,
			departments: Object.keys(data['colors']) || [],
		}),
		onError: () => {
			enqueueToast(
				{
					title: 'Ошибка',
					message: 'Не удалось загрузить настройки приложения',
				},
				{ variant: 'error' },
			)
		},
	})
}

export default useSettings
