import React from 'react'
import styled from 'styled-components';
import { FormControls } from '@/components/ui/form/FormControls';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { makeEmployeeMobileData } from './makeEmployeeData';
import { translate } from '@/i18n';

const MobileEmployeeItem = ({ name, fields, openUser }) => {
    return (
        <ItemWrapper>
            <Name>{name}</Name>
            <PropsWrapper>
                {fields?.map((item, idx) => (
                    <Property key={idx}>
                        <PropName>{item.name}</PropName>
                        <PropValue>{item.value}</PropValue>
                    </Property>
                ))}
            </PropsWrapper>
            <FormControls>
                <PrimaryButton onClick={openUser}>{translate('more')}</PrimaryButton>
            </FormControls>
        </ItemWrapper>
    )
}

export default MobileEmployeeItem

const ItemWrapper = styled.div`
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 15px 10px;
    color: #000;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`

const PropsWrapper = styled.div`
    margin: 6px 0 12px 0;
`

const PropName = styled.div`
    max-width: 100px;
    width: 100%;
`
const PropValue = styled.div`
    width: 100%;
    font-weight: 700;
`

const Property = styled.div`
    display: flex;
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const Name = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
`