import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import { Field, Formik } from 'formik'
import styled from 'styled-components'
import Grid from '@/ui/components/Grid'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import { SelectField } from '@/ui/components/Field/Select'
import { FormControls } from '@/components/ui/form/FormControls'
import { useMaintenanceDialogStore } from './MaintenanceModal'
import { InputField } from '@/ui/components/Field/Input'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'
import { useMutation } from 'react-query'
import { MailService } from '@/api/services/mail.service'
import { useToast } from '../shared/toast/useToast'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useMemo } from 'react'
import { useUserStore } from '@/stores/userStore'

const mergeMessage = (
	seat: string,
	created: string,
	reason: string,
	message: string,
) =>
	[
		`Номер места: ${seat}`,
		`Запросил: ${created}`,
		`Причина: ${reason}`,
		`Комментарий: ${message}`,
	].join('.<br />')

const MaintenanceForm: React.FC = () => {
	// data selectors
	const onClose = useMaintenanceDialogStore((state) => state.onClose)
	const seat = useMaintenanceDialogStore((state) => state.seat)
	const type = useMaintenanceDialogStore((state) => state.type)
	const employee = useMaintenanceDialogStore((state) => state.employee)
	const user = useUserStore((state) => state.user)

	const { data: metadata } = useMetadata()
	const intl = useIntl()
	const { enqueueToast } = useToast()

	const maintenanceVariants = useSettingsSelector(
		(settings) => settings.maintenance,
	)
	const typeName = useMemo(
		() => (type && metadata ? metadata?.rawNodes[type]?.name : undefined),
		[type, metadata],
	)

	const types = useMemo(() => {
		const variants =
			maintenanceVariants && maintenanceVariants.length
				? maintenanceVariants
				: []

		if (!typeName || !maintenanceVariants) return []
		const typeVariants = variants.find((option) =>
			option.types
				.map((opt) => opt.toLowerCase())
				.includes(typeName.toLowerCase()),
		)
		const defaultVariants = variants.find((option) =>
			option.types.map((opt) => opt.toLowerCase()).includes('default'),
		)

		let returnVariants = []

		if (typeVariants && typeVariants['options']) {
			returnVariants = typeVariants['options']
		} else if (defaultVariants && defaultVariants['options']) {
			returnVariants = defaultVariants['options']
		}
		return returnVariants.map(
			(item: { type: string; recipient: string }, idx) => ({
				label: item.type,
				value: idx + ' ' + item.recipient,
			}),
		)
	}, [typeName, maintenanceVariants])

	const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
		setSubmitting(true)

		const [_, email] = values.type.split(' ') || ['', '']
		const subject =
			types.find((type) => type.value === values.type)?.label || ''

		if (values.more.trim().length < 3) {
			enqueueToast('Комментарий должен содержать более 3 символов', {
				variant: 'error',
			})
			setSubmitting(false)
			return
		}

		mutate({
			seat: values.seat,
			employee: values.employee,
			subject: subject,
			more: values.more,
			type: email,
		})

		setSubmitting(false)
	}

	const { mutate } = useMutation(
		'maintenance-user',
		(data: any) =>
			MailService.sendMail({
				subject: data.subject || 'Обслуживание',
				message: mergeMessage(
					data.seat,
					user ? `${user?.display}` : '',
					data.subject,
					data.more || '-',
				),
				recipient: data.type,
			}),
		{
			onSuccess(data) {
				onClose()
				enqueueToast('Отправлено', { variant: 'success' })
			},
			onError(data: any) {
				enqueueToast('Не удалось отправить', { variant: 'error' })
			},
		},
	)

	return (
		<Formik
			onSubmit={handleFormSubmit}
			enableReinitialize
			initialValues={{
				seat,
				employee,
				type: types?.at(0)?.value,
			}}
		>
			{({
				handleSubmit,
				values,
				isSubmitting,
				errors,
				isValid,
				setFieldValue,
			}) => (
				<form onSubmit={handleSubmit}>
					<FormContainer>
						<Grid container>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('place')}</FormLabel>
								<Field
									name="seat"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'place' })}
									component={InputField}
									disabled={true}
									style={{ opacity: 0.5 }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('employee')}</FormLabel>
								<Field
									name="employee"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'employee' })}
									component={InputField}
									disabled={true}
									style={{ opacity: 0.5 }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('service-type')}</FormLabel>
								<Field
									name="type"
									$fullWidth
									required={true}
									component={SelectField}
									options={types}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('more-info')}</FormLabel>
								<Field
									name="more"
									$fullWidth
									placeholder={intl.formatMessage({ id: 'describe' })}
									component={InputField}
									textarea
									required={true}
									rows={10}
								/>
							</Grid>
						</Grid>
					</FormContainer>

					<ControlsWrapper>
						<FormControls>
							<PrimaryButton $fullWidth type="submit" disabled={isSubmitting}>
								{translate('send')}
							</PrimaryButton>
							<DefaultButton $fullWidth type="button" onClick={onClose}>
								{translate('cancel')}
							</DefaultButton>
						</FormControls>
					</ControlsWrapper>
				</form>
			)}
		</Formik>
	)
}

const FormContainer = styled.div`
	padding: 1rem 0;
	max-height: 100%;
`

export default MaintenanceForm

const ControlsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`
