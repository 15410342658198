import React from 'react'
import styled, { css } from 'styled-components'
import bigEmplolyee from '@/images/big-employee.png'
import CloseIcon from './icons/CloseIcon'
import Grid from './Grid'
import { animated, useTransition } from 'react-spring'
import { easePoly } from 'd3-ease'
import { useGlobalStore } from '@/stores/globalStore'
import Avatar from '@/ui/components/Avatar'
import { useUser } from '@/api/hooks/useUser'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { RuntimeFieldData } from '@/api/user'
import { IUserData } from '@/api/services/user.service'
import { useUserStore } from '@/stores/userStore'
import { formatPhoneNumber } from '@/utils/helpers/phone.helpers'
import { isEmail } from '@/utils/helpers/string.helpers'
import EmailIcon from '@/components/ui/icon/EmailIcon'
import Draggable from 'react-draggable'
import DraggableControl from '@/components/draggable/draggable-control/DraggableControl'
import { useUserAvatar } from '@/components/layout/AppBar/user/User'

function multiIndex(obj, is) {
	return is.length ? multiIndex(obj[is[0]], is.slice(1)) : obj
}

function pathIndex(obj, is) {
	return multiIndex(obj, is.split('.'))
}

export const makeUserCardData = (
	user?: IUserData,
	fields?: RuntimeFieldData[],
) => {
	if (!user || !fields) return []

	const paths = fields.map((field) => ({
		uid: field.uid,
		label: field.label,
		attribute: field.attribute,
		alias: field.alias,
		value: field.read_path,
		type: field.type,
	}))

	return paths.map((path) => {
		const property = {
			uid: path.uid,
			name: path.label,
			attribute: path.attribute,
			alias: path.alias,
			value: pathIndex(user, path.value),
			type: path.type,
		}

		return property
	})
}

const EmployeeBar = () => {
	const employee = useGlobalStore((state) => state.employee)
	const setEmployee = useGlobalStore((state) => state.setEmployee)
	const role = useUserStore((state) => state.role)

	const { data } = useUser(employee)
	const { data: extra } = useUserCardFields()

	const users = makeUserCardData(data?.user, extra?.fields)

	const transitions = useTransition(employee, {
		from: { opacity: 0, translateY: 100, translateX: '-50%' },
		enter: { opacity: 1, translateY: 0, translateX: '-50%' },
		leave: { opacity: 0, translateY: 100, translateX: '-50%' },
		config: {
			duration: 300,
			easing: easePoly.exponent(2),
		},
	})
	const userAvatar = useUserAvatar(employee)

	return transitions(
		(style, item) =>
			item && (
				<Draggable handle=".handle">
					<Wrapper style={style}>
						<Container $expanded={true}>
							<DraggableControl className="handle" />
							<Close onClick={() => setEmployee(null)}>
								<CloseIcon color="#000" />
							</Close>
							{data && data.user && (
								<>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Avatar src={userAvatar.src || bigEmplolyee} size="large" />
									</div>
									<Title>{data.user.display}</Title>

									<Table>
										{/*{role === RolesEnum.Admin && users.filter(v => v.uid.includes('booking_category')).map(property => (*/}
										{/*    <Grid.Row $cols="1fr 1fr" key={property.uid}>*/}
										{/*        <Grid.Item>{translate(property.alias)}</Grid.Item>*/}
										{/*        <Grid.Item $bold>{property.value}</Grid.Item>*/}
										{/*    </Grid.Row>*/}
										{/*))}*/}
										{users
											.filter(
												(v) => v.value && !v.uid.includes('booking_category'),
											)
											.map((property) => (
												<tr key={property.uid}>
													<td>{property.name}</td>
													<td>
														{isEmail(property.value) ? (
															<a href={`mailto:${property.value}`}>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	<div
																		style={{
																			marginRight: 4,
																			display: 'flex',
																			alignItems: 'center',
																			flexShrink: 0,
																		}}
																	>
																		<EmailIcon />
																	</div>
																	<div> {property.value}</div>
																</div>
															</a>
														) : property.type === 'phone' ? (
															formatPhoneNumber(property.value)
														) : (
															property.value
														)}
													</td>
												</tr>
											))}
									</Table>
								</>
							)}
						</Container>
					</Wrapper>
				</Draggable>
			),
	)
}

export default EmployeeBar

const Close = styled.div`
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;

	&::before {
		content: ' ';
		position: absolute;
		border-radius: 50%;
		top: -5px;
		left: -5px;
		width: 40px;
		height: 40px;
		background: #f5f5f5;
		z-index: -1;
		opacity: 0;
		transition: opacity 0.5s;
	}

	&:hover {
		&::before {
			opacity: 1;
		}
	}
`

const Container = styled.div<{ $expanded: boolean }>`
	margin: 1rem 1.6rem;
	max-height: 0;
	height: auto;
	transition: all 0.5s;
	overflow: hidden;

	${Grid.Row} {
		padding: 1rem 0;
	}

	${({ $expanded }) =>
		$expanded &&
		css`
			max-height: 100%;
		`}
`

const Title = styled.div`
	font-size: 1.6rem;
	line-height: 3rem;
	font-weight: 500;
	margin: 1.2rem 0;
	text-align: center;
	color: #000000;
`

const Wrapper = styled(animated.div)`
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	max-height: 100%;
	overflow-y: auto;
	max-width: 400px;
	min-height: 90px;
	width: 100%;
	z-index: 9101;

	background: #f5f5f5;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
	border-radius: 5px;
`

const SectionsWrapper = styled.div`
	display: flex;
	padding: 1rem 1rem 0 1rem;
`

const Section = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-basis: 33.3%;
	cursor: pointer;
	position: relative;
	z-index: 10;

	&::before {
		content: ' ';
		position: absolute;
		border-radius: 50%;
		top: 0;
		width: 40px;
		height: 40px;
		background: #f5f5f5;
		z-index: -1;
		opacity: 0;
		transition: opacity 0.5s;
	}

	&:hover {
		&::before {
			opacity: 1;
		}
	}
`

const Table = styled.table`
	margin-top: 12px;

	td,
	th {
		text-align: left;
	}

	td {
		font-size: 12px;
		//width: 50%;
		vertical-align: middle;
		white-space: nowrap;
	}

	tr {
		padding: 1rem 0px;
	}

	tr td:first-child {
		padding: 4px 12px 4px 0;
		width: 30%;
		white-space: break-spaces;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	tr td:last-child {
		font-weight: 700;
		word-break: break-word;
		white-space: break-spaces;
		padding: 4px 0px 4px 4px;
	}
`
