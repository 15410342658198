import { BASE_DOMAIN } from '@/api'
import { useMetadata } from '@/api/hooks/useMetadata'
import {
	defaultMapPointImage,
	getImageLink,
} from '@/components/shared/map/point/Point'
import { translate } from '@/i18n'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { format } from 'date-fns'
import React from 'react'
import styled, { css } from 'styled-components'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { useUserAvatar } from '@/components/layout/AppBar/user/User'

export const layerChangeEvent = (id) =>
	new CustomEvent('layer-change', { detail: { layerId: id } })

const SearchItem = ({ data, handleSearchClose }) => {
	// data selectors
	const setSeat = useGlobalStore((state) => state.setSeat)
	const setEmployee = useGlobalStore((state) => state.setEmployee)
	const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
	const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
	const setZoomSeat = useMapStore((state) => state.setZoomSeat)
	const apiUrl = useSettingsSelector(
		(settings) => settings.api.url,
		BASE_DOMAIN,
	)

	const { metadata } = useMetadata()

	if (!data) return null

	if (data.type === 'node') {
		const path = data.path.split('\\')
		const nodes = metadata?.data?.metablock?.nodes || {}
		const node = nodes[data.type_uid]
		const imgSrc =
			getImageLink(node.icon, apiUrl) ||
			getImageLink(defaultMapPointImage, apiUrl)
		const handleSeatChange = () => {
			setActiveLayer(data.parent)
			setSeat(null)
			setZoomSeat(data.id)

			setTimeout(() => {
				setSeat(data.id)
			}, 1)

			document.dispatchEvent(layerChangeEvent(data.parent))

			if (typeof handleSearchClose === 'function') {
				handleSearchClose()
			}
		}

		const place = path.length > 2 ? path[path.length - 2] : ''

		return (
			<SearchElement
				id={Number(data.id)}
				name={data.name}
				src={imgSrc}
				properties={[
					{
						id: 'type',
						name: translate('type'),
						value: data.type_name,
					},
					{
						id: 'position',
						name: translate('position'),
						value: place,
					},
				]}
				onMouseDown={handleSeatChange}
				onTouchEnd={handleSeatChange}
			/>
		)
	}

	if (data.type === 'booking') {
		const nodes = metadata?.data?.metablock?.nodes || {}
		const node = nodes[data.type_uid]
		const imgSrc =
			getImageLink(node.icon, apiUrl) ||
			getImageLink(defaultMapPointImage, apiUrl)

		const handleBookOpen = () => {
			setActiveLayer(data.layer_id)
			setSeatEmployee(data.user_id)
			setSeat(null)
			setZoomSeat(data.place_id)
			setTimeout(() => {
				setSeat(data.place_id)
			}, 1)
			if (typeof handleSearchClose === 'function') {
				handleSearchClose()
			}
		}

		return (
			<SearchElement
				id={Number(data.id)}
				name={data.name}
				src={imgSrc}
				properties={[
					{
						id: 'place',
						name: translate('place'),
						value: data.place,
					},
					{
						id: 'booking',
						name: translate('booking'),
						value: `${format(new Date(data.begin), 'dd.MM.yyyy HH:mm')}${
							data.end
								? ` - ${format(new Date(data.end), 'dd.MM.yyyy HH:mm')}`
								: ''
						}`,
					},
				]}
				onMouseDown={handleBookOpen}
				onTouchEnd={handleBookOpen}
			/>
		)
	}

	if (data.type === 'user') {
		const pathFields =
			Object.keys(data).find((key) => key.includes('fields')) || ''
		const userFields = pathFields ? JSON.parse(data[pathFields]) : []

		const handleUser = () => {
			setEmployee(data.id)
			if (typeof handleSearchClose === 'function') {
				handleSearchClose()
			}
		}

		return (
			<SearchElement
				id={Number(data.id)}
				name={data.name}
				src={data.id}
				properties={
					userFields?.map((field) => ({
						name: field.label,
						value: field.value.toString(),
					})) || []
				}
				onMouseDown={handleUser}
				onTouchEnd={handleUser}
			/>
		)
	}

	const path = data.path.split('\\')
	const properties = [
		{
			id: 'type',
			name: translate('type'),
			value: data.type_name,
		},
	]

	if (path && path[path.length - 2]) {
		properties.push({
			id: 'position',
			name: translate('position'),
			value: path[path.length - 2],
		})
	}

	const handleActiveLayer = () => {
		setActiveLayer(data.id)
		document.dispatchEvent(layerChangeEvent(data.id))
		if (typeof handleSearchClose === 'function') {
			handleSearchClose()
		}
	}

	return (
		<SearchElement
			id={Number(data.id)}
			name={data.name}
			properties={properties}
			onMouseDown={handleActiveLayer}
			onTouchEnd={handleActiveLayer}
		/>
	)
}

type SearchElementsProperty = {
	id: string
	name: string | JSX.Element
	value: string
}

type SearchElementProps = {
	id: number
	src?: string
	name: string
	properties?: SearchElementsProperty[]
	onMouseDown: any
	onTouchEnd: any
}

const SearchElement: React.FC<SearchElementProps> = ({
	id,
	src = '',
	name = '',
	properties = [],
	...otherProps
}) => {
	const avatarSrc = useUserAvatar(src)
	return (
		<Wrapper {...otherProps}>
			<Avatar src={avatarSrc.src} />
			<SearchData>
				<Name>{name}</Name>
				{properties.map((property) => (
					<Property key={property.id}>
						<PropertyName>{property.name}</PropertyName>
						<PropertyValue>{property.value}</PropertyValue>
					</Property>
				))}
			</SearchData>
		</Wrapper>
	)
}

export default SearchItem

const NullAvatar = styled.div<{ $src?: string }>`
	${({ $src }) =>
		$src &&
		css`
			background-image: url(${$src});
		`}
	background-color: #C4C4C4;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`

const Avatar = ({ src, size = 'medium' }) => {
	return <NullAvatar $src={src} />
}

const PropertyName = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #000000;
`
const PropertyValue = styled(PropertyName)`
	font-weight: 700;
	padding-left: 8px;
`

const Property = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: #000000;
	display: grid;
	grid-template-columns: auto 1fr;
	margin-top: 6px;
`

const Name = styled.div`
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: #000000;
`

const SearchData = styled.div`
	flex-grow: 1;
`
const Wrapper = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 50px 1fr;
	column-gap: 12px;
	padding: 12px;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}

	&:not(:last-child) {
		border-bottom: 1px solid #000000;
	}
`
