import React, { useState } from 'react'
import styled from 'styled-components'
import { useTree } from '@/api/hooks/useTree'
import ArrowIcon from '@/components/icons/ArrowIcon'
import { useGlobalStore } from '@/stores/globalStore'
import { checkTreeHidden } from '@/components/layout/Sidebar/Layers/TreeItem'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { useMetadata } from '@/api/hooks/useMetadata'

const DropDownContainer = styled('div')`
	width: 200px;
	height: 48px;
	margin: 0 auto;
`

const DropDownHeader = styled('div')`
	padding: 8px 16px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
	background: #38056c;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 1.6rem;
	color: #ffffff;
	text-align: center;

	height: 100%;
	width: 100%;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	line-height: 30px;

	position: relative;
`

const DropDownListContainer = styled('div')``

const DropDownList = styled('ul')`
	margin: 0;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
	padding: 0 8px;
	box-sizing: border-box;
	color: #000;
	font-size: 1.3rem;
	font-weight: 500;

	overflow: hidden;
	overflow-y: auto;
	max-height: calc(100vh - 150px);
`

const ListItem = styled('li')`
	list-style: none;
	height: 48px;
	cursor: pointer;

	text-align: center;
	line-height: 48px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:not(:last-child) {
		border-bottom: 1px solid #e5e5e5;
	}

	&:hover {
		background: rgb(7 157 172 / 20%);
	}
`

const ExpandBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 16px;

	transform: rotate(-90deg);
`

const FloorSelector = ({ text }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectedOption, setSelectedOption] = useState<null | {
		id: string
		name: string
	}>(null)

	const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
	const { layers } = useTree()
	const { metadata } = useMetadata()
	const role = useUserStore((state) => state.role)

	const toggling = () => setIsOpen(!isOpen)

	const onOptionClicked = (value) => () => {
		setSelectedOption(value)
		setIsOpen(false)
		setActiveLayer(value.id)
	}

	const options =
		layers?.nodes.filter((n) =>
			n.ownView && role === RolesEnum.Admin
				? true
				: !checkTreeHidden(n, metadata?.layers),
		) || []

	return (
		<DropDownContainer>
			<DropDownHeader onClick={toggling}>
				{text}
				<ExpandBtn>
					<ArrowIcon color="#fff" />
				</ExpandBtn>
			</DropDownHeader>
			{isOpen && (
				<DropDownListContainer>
					<DropDownList>
						{options.map((option) => (
							<ListItem
								onClick={onOptionClicked({ id: option.id, name: option.name })}
								key={option.id}
							>
								{option.name}
							</ListItem>
						))}
					</DropDownList>
				</DropDownListContainer>
			)}
		</DropDownContainer>
	)
}

export default FloorSelector
